<template>
    <div class="hwk-manage">
        <van-nav-bar title="首页" />
        <van-grid>
            <van-grid-item v-show="username == 'admin'" icon="photo-o" text="信息录入" to="/hwk/input" />
            <van-grid-item icon="photo-o" text="小测曲线图" to="hwk/echart" />
        </van-grid>
    </div>
</template>
<script>
export default {
    name: 'hwkmanage',
    data() {
        return {
            username: ''
        }
    },
    created() {
        this.username = localStorage.getItem('uname')
        console.log(this.username, '----------')
    },
    method: {
        goManage() {
            console.log('in.........')
        }
    }
}
</script>
<style lang="less" scoped>
.hwk-manage {
    width: 100%;
    &btn {
        // width: 200px;
    }
}
</style>
