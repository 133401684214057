var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "hwk-manage" },
    [
      _c("van-nav-bar", { attrs: { title: "首页" } }),
      _c(
        "van-grid",
        [
          _c("van-grid-item", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.username == "admin",
                expression: "username == 'admin'"
              }
            ],
            attrs: { icon: "photo-o", text: "信息录入", to: "/hwk/input" }
          }),
          _c("van-grid-item", {
            attrs: { icon: "photo-o", text: "小测曲线图", to: "hwk/echart" }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }